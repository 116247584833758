/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
//import MDButton from "components/MDButton";
import { Button } from "@mui/material";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in2-basic.webp";
import logoImage from "assets/images/moona-logo.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { ColorRing } from "react-loader-spinner";
import Swal from 'sweetalert2'



function SignUp() {
  const [signup, setSignup] = useState({
    user_name: "",
    first_name: "",
    last_name: "",
    email_c: "",
    user_hash: "",
    cnfrm_c: "",
  });
  const [UserData,setUserData] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    emptyFeilds : "",
    valiatation: "",
    matchError: "",
    userNameError: "",
    emailError: "",
    phoneError: "",
    userNameMatchErr : "",
    emailDuplicateErr:"",
    phoneNumberDuplicateErr:""
  });
  const [isValid, setIsValid] = useState(true)
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const usernameRegex = /^[a-zA-Z0-9]{6,15}$/;
  // password valitation //
 

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const restValue = queryParams.get("referral_id");
  // console.log(restValue);

  const handleUserGet = async() => {
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/customapi/Create.php";
    try {
      const response = await axios.get(apiUrl);

      if (response.status === 200) {
        console.log("User data", response.data);
        const entryList = response.data.entry_list;

      // Extracting name_value_list from each entry in entry_list and storing them in the products array
      const products = entryList.map((entry) => entry.name_value_list);
        setUserData(products)
        console.log(products);
        

        // console.log("payload", payload);

        // navigate("/dashboard");

        // Handle successful login, e.g., store token, redirect, etc.
      } else {
        console.error("Login failed:", response.data);
      }
    } catch (error) {
     
      if (error.response) {
        console.error("An error occurred during login:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  }

  useEffect(()=>{
    handleUserGet()
  },[])

  const handleChange = (e) => {
    const { name, value } = e.target;
    let isFormValid = true; // Assume form is valid initially
  
    setSignup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  
    // Username validation
    if (name === "user_name") {
      if (!usernameRegex.test(value)  ) {
        setError((prevError) => ({
          ...prevError,
          userNameError: "*Username must be 6-15 characters long and contain only letters and numbers.*",
        }));
        isFormValid = false; // Mark form as invalid if username doesn't match the regex
      } else {
        setError((prevError) => ({
          ...prevError,
          userNameError: "", // Clear the error if username is valid
        }));
      }
    }
  // debugger
   
    // Password validation
    if (name === "user_hash") {
      if (!passwordRegex.test(value)) {
        setError((prevError) => ({
          ...prevError,
          valiatation: "*Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.*",
        }));
        isFormValid = false; // Mark form as invalid if password doesn't match the regex
      } else {
        setError((prevError) => ({
          ...prevError,
          valiatation: "", // Clear the error if password is valid
        }));
      }
    }
  
    // Confirm password validation
    if (name === "cnfrm_c") {
      if (value !== signup.user_hash) {
        setError((prevError) => ({
          ...prevError,
          matchError: "Passwords do not match",
        }));
        isFormValid = false; // Mark form as invalid if passwords don't match
      } else {
        setError((prevError) => ({
          ...prevError,
          matchError: "", // Clear error if passwords match
        }));
      }
    }

    const MobileRegex = /^(?:\+91|91)?[6-9][0-9]{9}$/;
    if (name === "phone_mobile") {
      if (!MobileRegex.test(value)) {
        setError((prevError) => ({
          ...prevError,
          phoneError: "Enter valid Phone Number",
        }));
        isFormValid = false; // Mark form as invalid if passwords don't match
      } else {
        setError((prevError) => ({
          ...prevError,
          phoneError: "", // Clear error if passwords match
        }));
      }
    }

    
    
  
    // Only set `isValid` if all validations are passed
    if (
      !error.userNameError &&
      !error.valiatation &&
      !error.matchError &&
      !error.phoneError &&
      isFormValid
    ) {
      setIsValid(true); // If there are no errors and form is valid, allow submission
    } else {
      setIsValid(false); // Otherwise, prevent submission
    }
  };



 
   
  
  
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    // Initialize form validation flag
    let formValid = true;
  
    // Validation check for all empty fields
    if (signup.user_name === "" || signup.first_name === "" || signup.last_name === "" || signup.email_c === "" || signup.phone_mobile === "" || signup.user_hash === "" || signup.cnfrm_c === "") {
      // At least one field is empty, show specific error
      setError((prevError) => ({
        ...prevError,
        emptyFeilds: "*Please fill in all required fields*",
      }));
      // console.log(error.emptyFeilds, "test");
      alert("*Please fill in all required fields*")
      
      formValid = false;
      
    } else {
      setError((prevError) => ({
        ...prevError,
        emptyFeilds: "",
      }));
      
    }
  
    // Duplicate checks
    const userNameExists = UserData.some((user) => user.user_name?.value === signup.user_name);
    const emailExists = UserData.some((user) => user.email_c?.value === signup.email_c);
    const phoneNumberExists = UserData.some((user) => user.phone_mobile?.value === signup.phone_mobile);
  
    // Check for duplicate username
    if (userNameExists) {
      setError((prevError) => ({
        ...prevError,
        userNameMatchErr: "*Username is already taken*",
      }));
      formValid = false;
    } else {
      setError((prevError) => ({
        ...prevError,
        userNameMatchErr: "",
      }));
    }
  
    // Check for duplicate email
    if (emailExists) {
      setError((prevError) => ({
        ...prevError,
        emailDuplicateErr: "*Email is already taken*",
      }));
      formValid = false;
    } else {
      setError((prevError) => ({
        ...prevError,
        emailDuplicateErr: "",
      }));
    }
  
    // Check for duplicate phone number
    if (phoneNumberExists) {
      setError((prevError) => ({
        ...prevError,
        phoneNumberDuplicateErr: "*Mobile number is already taken*",
      }));
      formValid = false;
    } else {
      setError((prevError) => ({
        ...prevError,
        phoneNumberDuplicateErr: "",
      }));
    }
  
    // If the form is invalid, stop the submission process
    if (!formValid) {
      console.log("Form is invalid. Preventing submission.");
      return; // Prevent form submission
    }
  
    // If form is valid, proceed with API call
    setLoading(true); // Show spinner/loading state
  
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/customapi/Create.php";
  
    const payload = {
      fields: [
        { name: "user_name", value: signup.user_name },
        { name: "user_hash", value: signup.user_hash },
        { name: "cnfrm_c", value: signup.cnfrm_c },
        { name: "first_name", value: signup.first_name },
        { name: "last_name", value: signup.last_name },
        { name: "email_c", value: signup.email_c },
        { name: "phone_mobile", value: signup.phone_mobile },
        { name: "employee_status", value: "Active" },
        { name: "status", value: "active" },
        { name: "refer", value: restValue ? restValue : "" },
      ],
    };
  
    try {
      const response = await axios.post(apiUrl, payload);
  
      if (response.status === 200 && !response.data.id) {
        console.log("Registration successful:", response.data);
        Swal.fire({
          title: "Good job!",
          text: "Registration Successfully Completed",
          icon: "success"
        });
        navigate("/authentication/sign-in"); // Redirect to sign-in page on success
      } else {
        console.error("Registration failed:", response.data);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    } finally {
      setLoading(false); // Stop spinner/loading state
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card sx={{ width: "auto", maxWidth: "700px" }}>
        <MDBox>
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <img src={logoImage} alt="Moona" style={{ width: "85px", height: "75px" }} />
          </MDTypography>
        </MDBox>
        <form onSubmit={handleLogin}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox role="form">
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Username"
                  onChange={handleChange}
                  value={signup.user_name}
                  name="user_name"
                  id="user_name"
                  variant="standard"
                  
                  fullWidth
                />
                <p className="text-red-700 text-[12px]">{error.userNameError}</p>
                <p className="text-red-700 text-[12px]">{error.userNameMatchErr}</p>
                
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="First Name"
                  onChange={handleChange}
                  value={signup.first_name}
                  name="first_name"
                  id="first_name"
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Last Name"
                  onChange={handleChange}
                  value={signup.last_name}
                  name="last_name"
                  id="last_name"
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  onChange={handleChange}
                  value={signup.email_c}
                  name="email_c"
                  id="email_c"
                  variant="standard"
                  fullWidth
                />
                <p className="text-red-700 text-[12px]">{error.emailError}</p>
                <p className="text-red-700 text-[12px]">{error.emailDuplicateErr}</p>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="number"
                  label="Mobile No:"
                  onChange={handleChange}
                  value={signup.phone_mobile}
                  name="phone_mobile"
                  id="phone_mobile"
                  variant="standard"
                  // pattern="^[6789]\d{9}$"
                  fullWidth
                />
               <p className="text-red-700 text-[12px]">{error.phoneError}</p>
               <p className="text-red-700 text-[12px]">{error.phoneNumberDuplicateErr}</p>
                
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  onChange={handleChange}
                  value={signup.user_hash}
                  name="user_hash"
                  id="user_hash"
                  variant="standard"
                  fullWidth
                />
                <p className="text-red-700 text-[12px]">{error.valiatation}</p>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Confirm Password"
                  onChange={handleChange}
                  value={signup.cnfrm_c}
                  name="cnfrm_c"
                  id="cnfrm_c"
                  variant="standard"
                  fullWidth
                />
                <p className="text-red-700 text-[12px]">{error.matchError}</p>
              </MDBox>

              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox checked />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1, color: "#012034" }}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                  <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    sx={{ color: "#012034" }}
                    textGradient
                  >
                    Terms and Conditions
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
              {loading ? (
                  <div className="flex justify-center">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                    />
                  </div>
                ) : (
                
                <Button
                  type="submit"
                  variant="gradient"
                  sx={{
                    backgroundColor: "#012034",
                    color: "#ffffff",
                    width: "100%",
                    padding: "6px 8px", // Increase padding for a larger button
                    fontSize: "1rem",
                    "&:hover": {
                      backgroundColor: "#012034", // Slightly lighter shade for hover effect
                    },
                    "&:active": {
                      backgroundColor: "#012034", // Maintain the same color on click
                    },
                  }}
                >
                  Register
                </Button>
                )}
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" sx={{ color: "#012034" }}>
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    sx={{ color: "#012034" }}
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </CoverLayout>
  
  
  );
}

export default SignUp;
