import React, { useState } from 'react';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { TextField, Button, Checkbox, FormControlLabel, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from "layouts/authentication/sign-in"

const Container = styled(Box)(({ theme }) => ({
  background: '#F0F2F5', // Replace with your image URL
  backgroundPosition: 'center',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));

const Form = styled('form')(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.8)',
  padding: '2rem',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '400px',
}));

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: '1rem',
  width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '1rem',
}));

const ReferFriendForm = () => {
  const sessionId = useSelector((state) => state.session.sessionId);
  const user_id = useSelector((state) => state.session.setUserId ? state.session.setUserId.value : null );
  console.log(user_id);
  const [formValues, setFormValues] = useState({
    refferid_c: "",
    firstname_c: "",
    lastname_c: "",
    email_c: "",
    phone_number_c: "",
    // agree: false,
  });
  const navigate = useNavigate()
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Values:', formValues); // Debugging
    console.log('User ID:', user_id);

    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Ref_Refferals",
      // user_id :user_id,
      name_value_list: [
        { name: "refferid_c", value: formValues.refferid_c },
        { name: "firstname_c", value: formValues.firstname_c },
        { name: "lastname_c", value: formValues.lastname_c },
        { name: "email_c", value: formValues.email_c },
        { name: "phone_number_c", value: formValues.phone_number_c },
        { name: "user_id_c", value: user_id }
      ]
    });

    console.log(rest_data);
    
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
      rest_data
    )}`;

    try {
      const response = await axios.post(fullUrl);
      console.log("API Response:", response.data);

      navigate("/myReferral")
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during login:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }

  }

  return (
    <>
    {!sessionId ? <Login /> :
    <div>
    
      <DashboardNavbar />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Title variant="h5" component="h2" gutterBottom>
            Refer A Friend
          </Title>
          <Input
            label=" Friend First Name"
            variant="outlined"
            fullWidth
            name="firstname_c"
            value={formValues.firstname_c}
            onChange={handleChange}
          />
          <Input
            label="Friend Last Name"
            variant="outlined"
            fullWidth
            name="lastname_c"
            value={formValues.lastname_c}
            onChange={handleChange}
          />
          {/* <Input
            label="Date of Birth"
            type="date"
            variant="outlined"
            fullWidth
            name="dob"
            value={formValues.dob}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />  */}
          <Input
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            name="email_c"
            value={formValues.email_c}
            onChange={handleChange}
          />
          <Input
            label="Phone Number"
            type="tel"
            variant="outlined"
            fullWidth
            name="phone_number_c"
            value={formValues.phone_number_c}
            onChange={handleChange}
            required
          />
          <FormControlLabel
            control={
              <Checkbox checked />
            }
            label="I agree to the terms and conditions"
            style={{ marginBottom: '2rem' }}
          />
          <Button
            type="submit"
            variant="gradient"
            fullWidth
            sx={{
              backgroundColor: '#2B84EB',
              color: '#ffffff',
              width: '100%',
              padding: '6px 8px', // Increase padding for a larger button
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#2B84EB', // Slightly lighter shade for hover effect
              },
            }}
          >
            Submit
          </Button>
        </Form>
      </Container>

</div>
}
    </>
  );
};

export default ReferFriendForm;
