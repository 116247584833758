/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import JobLists from "components/Joblistings/JobLists";
import Login from "layouts/authentication/sign-in"
import { useState } from "react";
import { useSelector } from "react-redux";


// import { multiStepContext } from "../../stepContext";
function Dashboard() {
  const sessionId = useSelector((state) => state.session.sessionId || null);
  const user_id = useSelector((state) => state.session.setUserId ? state.session.setUserId.value : null);
  const { sales, tasks } = reportsLineChartData;
  // const { handleClose, handleOpen } = useContext(multiStepContext);
  const [open, setOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(true);
    handleOpen();
  };

  // Handle modal close
  const handleModalClose = () => {
    setOpen(false);
    handleClose();
  };


 

  return (
    <>
    {!sessionId ? <Login /> :
      <DashboardLayout >
        <DashboardNavbar />
      <JobLists />
    
      </DashboardLayout>
}
      </>
  );
}

export default Dashboard;
