// EditProfile.js
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import axios from "axios";
// import Overview, { handleEditClick } from './index';

const EditProfile = () => {
  const location = useLocation();
  const sessionNavigate = useNavigate();
  // const { userId, userName } = location.state || {};
  const sessionId = useSelector((state) => state.session.sessionId);
  const user_id = useSelector((state) =>
    state.session.setUserId ? state.session.setUserId.value : null
  );
  const editProfile = location.state;
  // console.log(editProfile.user_name.value);
  useEffect(() => {
    if (!sessionId) {
      sessionNavigate("/authentication/sign-in");
    }
  }, [sessionId]);

  // console.log(editProfile.profile,"test");

  // console.log(getProfileData,"recent");

  const [profile, setProfile] = useState({
    firstName: editProfile.profile.first_name.value,
    lastName: editProfile.profile.last_name?.value,
    dob: editProfile.profile.dob_c?.value,
    email: editProfile.profile.email_c?.value,
    mobile: editProfile.profile.phone_mobile?.value,
    referredBy: "Anand",
    street: editProfile.profile.address_street?.value,
    city: editProfile.profile.address_city?.value,
    state: editProfile.profile.address_state?.value,
    country: editProfile.profile.address_country?.value,
    postelCode: editProfile.profile.address_postalcode?.value,
    description: editProfile.profile.selfdescription_c?.value,
  });

  // setProfile(editProfile)

  // useEffect(()=>{
  //   handleEditClick()
  // },[])

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/customapi/Create.php";

    const payload = {
      fields: [
        // { name: "id", value: editProfile.profile.id?.value },

        { name: "first_name", value: profile.firstName },
        { name: "last_name", value: profile.lastName },
        { name: "dob_c", value: profile.dob },
        { name: "address_street", value: profile.street },
        { name: "address_city", value: profile.city },
        { name: "address_state", value: profile.state },
        { name: "address_country", value: profile.country },
        { name: "address_postalcode", value: profile.postelCode },
        { name: "selfdescription_c", value: profile.description },
      ],
    };
    // console.log(rest_data);

    try {
      const response = await axios.post(apiUrl, payload);
      console.log("API Response:", response.data);
      navigate("/profile");
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during login:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };
  const handleCancel = () => {
    navigate(-1); // Go back to the previous page/component
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={profile.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={profile.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="date"
              label="Date of Birth"
              name="dob"
              value={profile.dob}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={profile.email}
              // onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Mobile"
              name="mobile"
              value={profile.mobile}
              // onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Referred By"
              name="referredBy"
              value={profile.referredBy}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Street"
              name="street"
              value={profile.street}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={profile.city}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="State"
              name="state"
              value={profile.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={profile.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              label="Pin Code"
              name="postelCode"
              value={profile.postelCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="About You"
              name="description"
              value={profile.description}
              onChange={handleChange}
              multiline
              rows={4} // Set the number of rows
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ color: "#FFFFFF", mr: 2 }}
              >
                Save
              </Button>

              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#f44336" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default EditProfile;
