import { Container, Step, StepLabel, Stepper } from '@mui/material';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import CategoryStep from './categoryStep';
import { useSelector } from 'react-redux';
import ReferralStep from "./ReferralStep"

const MultiForm = () => {
  const step = useSelector((state) => state.form.step || 1);
  console.log(step);
  

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return <CategoryStep />;
      case 2:
        return <Step1 />;
      case 3:
        return <Step2 />;
      case 4:
        return <Step3 />;
      case 5:
        return <ReferralStep />;
      // Add more cases for additional steps
      
    }
  };
 


  return (
    <>
      <DashboardNavbar />
      <Container maxWidth="xl" className='my-6'>
        <div className='text-center max-md:block hidden  pb-2 font-bold '>Job Application</div>
        <Stepper alternativeLabel activeStep={step - 1}  orientation="horizontal">
          <Step>
            <StepLabel ><span className='text-[14px] font-bold'>1</span> <span className='max-md:hidden'>Category details</span></StepLabel>
          </Step>
          <Step>
            <StepLabel ><span className='text-[14px] font-bold'>2</span> <span className='max-md:hidden'>Personal Details</span> </StepLabel>
          </Step>
          <Step>
            <StepLabel ><span className='text-[14px] font-bold'>3</span> <span className='max-md:hidden'>Educational Details</span></StepLabel>
          </Step>
          <Step>
            <StepLabel ><span className='text-[14px] font-bold'>4</span> <span className='max-md:hidden'>Experience Details</span></StepLabel>
          </Step>
          <Step>
            <StepLabel ><span className='text-[14px] font-bold'>5</span> <span className='max-md:hidden'>Referral details</span></StepLabel>
          </Step>
        </Stepper>
        {renderStepContent(step)}
      </Container>
    </>
  );
};

export default MultiForm;
