import { combineReducers } from "redux";
import sessionReducer from "./sessionReducer";
import formSlice from "../../Features/jobForms/jobFormSlice"

const rootReducer  = combineReducers({


    session:sessionReducer,
    form:formSlice
})
export default rootReducer