import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import burceMars from "assets/images/moona-logo.png";
import { Navigate, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

function Header({ children,profile,props }) {
  console.log("test data==>",profile);
  
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [editableName, setEditableName] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const navigate = useNavigate() 

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  

  const toggleEditName = () => {
    setIsEditingName(!isEditingName);
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleEditClick = () => {
    navigate("/profile/editProfile", { state: {profile:profile} }); // Pass the data
  };
  console.log("header profile",profile);
  
  

  return (
    <MDBox position="relative" mb={5}>
      <Card >
        <Grid container spacing={2} alignItems="center" >
          <Grid item >
            <MDAvatar src={profileImage || burceMars} alt="profile-image" size="xl" shadow="sm" />
            <MDBox position="absolute" top={38} left={38} zIndex={1} p={1}>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUploadImage}
                id="upload-image-input"
              />
              <label htmlFor="upload-image-input" className="flex">
                <IconButton component="span">
                  <Icon fontSize="small">photo_camera</Icon>
                </IconButton>
                <h3 className="pl-[10px]">{profile.user_name?.value}</h3>
              </label>
              
            </MDBox>
          </Grid>
          <Grid item>
            {/* <Typography variant="h5" fontWeight="medium">
              {isEditingName ? (
                <TextField
                  value={editableName.user_name?.value}
                  onChange={handleNameChange}
                  onBlur={toggleEditName}
                  inputProps={{ style: { fontSize: 24 } }}
                />
              ) : (
                <>
                  {editableName}
                  <IconButton onClick={toggleEditName} sx={{ ml: 1 }}>
                    <Icon fontSize="small">edit</Icon>
                  </IconButton>
                </>
              )}
            </Typography> */}
          </Grid>
          <Grid item xs={2} md={6} lg={2} sx={{ ml: "auto",px:"10px" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab label="Add More Details" icon={<FaEdit />} onClick={handleEditClick} />
                {/* <Tab label="Message" icon={<Icon fontSize="small">email</Icon>} />
                <Tab label="Settings" icon={<Icon fontSize="small">settings</Icon>} /> */}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;