import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux"; // Missing import for useSelector
import { Link } from "react-router-dom";
import axios from "axios";

const JobLists = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [jobListing, setJobListing] = useState([]);

  const sessionId = useSelector((state) => state.session.sessionId); // Get sessionId from Redux

  const handleExpand = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "job_JOB_",
        select_fields: [
          "id",
          "name",
          "company_name_c",
          "location_c",
          "job_type_c",
          "category_c",
          "required_skills_c", // Ensure the key matches the API response
          "closedate_c"
        ],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
        rest_data
      )}`;

      try {
        const response = await axios.get(fullUrl);
        const entryList = response.data.entry_list;

        const products = entryList.map((entry) => entry.name_value_list);
        setJobListing(products);
      } catch (error) {
        if (error.response) {
          console.error("Error fetching data:", error.response.data);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [sessionId,jobListing]); // Removed jobListing from dependencies to avoid infinite loop

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <h1 className="w-full text-2xl font-bold text-center my-6">Job Listings</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full px-6">
        {jobListing.map((job) => (
          <div
            key={job.id?.value}
            className="bg-blue-500 text-white p-6 rounded-lg shadow-md flex flex-col space-y-2"
          >
            <h3 className="text-2xl font-bold capitalize">{job.name?.value}</h3>
            <div className="flex  flex-col ">
              <p><strong>Company:</strong> {job.company_name_c?.value}</p>
              <p><strong>Location:</strong> {job.location_c?.value}</p>
              {/* <p><strong>Type:</strong> {job.job_type_c?.value}</p> */}
              {/* <p><strong>Category:</strong> {job.category_c?.value}</p> */}
              <p><strong>Skills:</strong> {job.required_skills_c?.value}</p>
              <p><strong>Close Date:</strong> {job.closedate_c?.value}</p>
            </div>
            <div className="mt-4">
              <Link to="./jobApplication" style={{ textDecoration: "none" }}>
                <button className="bg-white text-blue-500 px-4 py-2 rounded hover:bg-blue-600 hover:text-white">
                  Apply Now
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobLists;
