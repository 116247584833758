// Overview.js
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";

function Overview() {
  const [profile, setProfileData] = useState([]);
  // const [data, setdata] = useState("test")
  const navigate = useNavigate();

  const user_id = useSelector((state) =>
    state.session.setUserId ? state.session.setUserId.value : null
  );
  console.log(user_id);
  const sessionId = useSelector((state) => state.session.sessionId);
  console.log(sessionId);

  useEffect(() => {
    if (!sessionId) {
      navigate("/authentication/sign-in");
      console.log(sessionId, "testt");
    }
  }, [sessionId, profile]);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Users",
        // query: "ref_refferals_cstm.user_id_c= 1",

        select_fields: [
          "id",
          "user_name",
          "first_name",
          "last_name",
          "email_c",
          "phone_mobile",
          "address_street",
          "address_state",
          "address_country",
          "address_city",
          "selfdescription_c",
          "dob_c",
          "address_postalcode",
        ],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
        rest_data
      )}`;

      try {
        const response = await axios.get(fullUrl);
        console.log("API Response:", response.data);
        if (response.data.name == "Invalid Session ID") {
          navigate("/authentication/sign-in");
        }
        const entryList = response.data.entry_list;

        // Extracting name_value_list from each entry in entry_list and storing them in the products array
        const products = entryList.map((entry) => entry.name_value_list);

        console.log(products);
        const filteredProfiles = products.filter((item) => item.id.value === user_id);
        const profileData = filteredProfiles.length > 0 ? filteredProfiles[0] : null;
        console.log("profile Data :", profileData,filteredProfiles);
        setProfileData(profileData);

        // setReferral(sortedData);
        // console.log(products[1].id);
        // setuser(products);

        // console.log(user, "data");
      } catch (error) {
        if (error.response) {
          console.error("An error occurred during get data:", error.response.data);
          console.error("Status code:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [sessionId]);

  // console.log(data, "profile data before navigating");

  // console.log(profile,"test");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />

      <Header profile={profile}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />

              <ProfileInfoCard
                title={[`${profile.first_name?.value} ${profile.last_name?.value}`]}
                description={profile.selfdescription_c?.value}
                info={{
                  // FirstName: profile.first_name?.value,
                  // LastName: profile.last_name?.value,
                  DOB: profile.dob_c?.value,
                  email: profile.email_c?.value,
                  mobile: profile.phone_mobile?.value,
                  Referred_by: "Anand",
                  Address: profile.address_street?.value,
                  City: profile.address_city?.value,
                  State: profile.address_state?.value,
                  Country: profile.address_country?.value,
                  Pincode: profile.address_postalcode?.value,
                }}
                action={{
                  route: "/profile/editProfile",
                  tooltip: "Edit Profile",
                  // onClick: handleEditClick,
                }}
                shadow={false}
                // onClick={handleEditClick}
              />

              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
